<template>
  <vxe-modal
    v-if="open"
    v-model="open"
    width="1200px"
    height="auto"
    :title="formTitle"
    @close="open = false"
    show-zoom
    resize
    :lock-view="false"
    :mask="true"
    :esc-closable="true"
    :position="{ top: '10%' }"
    remember
    transfer
    ref="templateModelRef"
  >
    <a-form-model ref="form" :model="form" :rules="rules" class="template-form">
      <a-row :gutter="48">
        <a-col :md="12" :sm="24">
          <a-form-model-item label="模板名称" prop="templateName">
            <a-input v-model="form.templateName" placeholder="请输入模板名称" />
          </a-form-model-item>
        </a-col>
        <a-col :md="12" :sm="24">
          <a-form-model-item label="推送范围" prop="pushRange">
            <a-select placeholder="请选择" v-model="form.pushRange" @change="onChangePushRange">
              <a-select-option v-for="(d, index) in msgPushScopeOptions" :key="index" :value="parseInt(d.dictValue)">{{
                d.dictLabel
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :md="12" :sm="24">
          <a-form-model-item label="用户类型" prop="userType">
            <a-select placeholder="请选择" v-model="form.userType" @change="onChangeUserType">
              <a-select-option
                v-for="(d, index) in msgUserTypeOptionsNew"
                :key="index"
                :value="parseInt(d.dictValue)"
                >{{ d.dictLabel }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :md="12" :sm="24">
          <a-form-model-item label="渠道选择" prop="channelType">
            <a-select placeholder="请选择" v-model="form.channelType" @change="onChangeChannelType">
              <a-select-option
                v-for="(d, index) in msgChannelTypeOptionsNew"
                :key="index"
                :value="parseInt(d.dictValue)"
                >{{ d.dictLabel }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :md="12" :sm="24">
          <a-form-model-item label="内容类型" prop="messageType">
            <a-select placeholder="请选择" v-model="form.messageType">
              <a-select-option v-for="(d, index) in messageTypeOptions" :key="index" :value="d.code">{{
                d.name
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :md="12" :sm="24">
          <a-form-model-item label="模板状态" prop="templateStatus">
            <a-radio-group v-model="form.templateStatus" button-style="solid">
              <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="parseInt(d.dictValue)">{{
                d.dictLabel
              }}</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
        <a-divider/>
        <a-col :md="12" :sm="24">
          <a-form-model-item label="模板内容" prop="templateContent">
            <a-textarea v-model="form.templateContent" placeholder="请输入模板内容" :autosize="{minRows: 4}"/>
            (模板内容内所有变量都必须以%s表示)
          </a-form-model-item>
        </a-col>
        <a-col :md="12" :sm="24">
          <div class="param-list">
            <div class="row">
              <span class="col header">变量名</span>
              <span class="col header">变量顺序</span>
              <span class="delete-btn header"></span>
            </div>
            <div class="row" v-for="(item, index) in form.paramsList" :key="index">
              <div class="child-row">
                <span class="col">
                  <!-- a-form-model-item组件内的验证规则要调用自定义验证函数validateParamName -->
                  <a-form-model-item :prop="`paramsList[${index}].paramName`" :rules="[{  required: true, trigger: 'change', validator: validateParamName }]">
                    <a-input v-model="item.paramName" placeholder="请输入变量名" />
                  </a-form-model-item>
                </span>
                <span class="col">
                  <a-form-model-item :prop="`paramsList[${index}].orderNum`" :rules="[{ required: true, trigger: 'change', message: '请输入变量顺序' }]">
                    <a-input-number v-model="item.orderNum" placeholder="请输入变量顺序" />
                  </a-form-model-item>
                </span>
                <span class="delete-btn">
                  <a-icon type="delete" @click="handleDeleteParam(index)" />
                </span>
              </div>
            </div>
          </div>
          <div class="add-btn">
            <a-button :disabled="readOnly" @click="handleAddParam" type="primary" class="add">
              <a-icon type="plus" />
            </a-button>
          </div>
        </a-col>
        <a-col :md="12" :sm="24">
          <a-form-model-item label="模板扩展参数" prop="templateExt">
            <vue-json-editor
              v-model="jsonExt"
              :showBtns="false"
              mode="code"
              lang="zh"
              :expanded-on-start="true"
              @json-change="onJsonChange"
              @json-save="onJsonSave"
              @has-error="onError"
            />
          </a-form-model-item>
        </a-col>
        <a-divider />
        <div class="bottom-control">
          <a-space>
            <a-button type="primary" @click="submitForm"> 保存 </a-button>
            <a-button type="dashed" @click="cancel"> 取消 </a-button>
          </a-space>
        </div>
      </a-row>
    </a-form-model>
  </vxe-modal>
</template>

<script>
import vueJsonEditor from 'vue-json-editor'
import { getTemplate, addTemplate, updateTemplate, queryMessageTypesByChannelType } from '@/api/message/template'

export default {
  name: 'CreateForm',
  props: {
    msgPushScopeOptions: {
      type: Array,
      required: true
    },
    msgUserTypeOptions: {
      type: Array,
      required: true
    },
    msgChannelTypeOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    vueJsonEditor
  },
  data () {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        templateId: null,
        templateName: undefined,
        templateContent: undefined,
        templateExt: '{}',
        pushRange: undefined,
        channelType: undefined,
        messageType: undefined,
        useCount: 0,
        templateStatus: 0,
        userType: undefined,
        paramsList: [],
        createUser: null,
        updateUser: null,
        createTime: null,
        updateTime: null,
        delFlag: null
      },
      jsonExt: {},
      hasJsonFlag: true, // json是否验证通过
      messageTypeOptions: [],
      msgUserTypeOptionsNew: [],
      msgChannelTypeOptionsNew: this.msgChannelTypeOptions,
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        templateName: [
          { required: true, message: '模板名称不能为空', trigger: 'blur' }
        ],
        templateContent: [
          { required: true, message: '模板内容不能为空', trigger: 'blur' }
        ],
        templateExt: [
          { required: true, message: '模板扩展参数不能为空', trigger: 'blur' }
        ],
        pushRange: [
          { required: true, message: '推送范围不能为空', trigger: 'blur' }
        ],
        templateStatus: [
          { required: true, message: '应用状态不能为空', trigger: 'blur' }
        ],
        channelType: [
          { required: true, message: '渠道类型不能为空', trigger: 'change' }
        ],
        messageType: [
          { required: true, message: '消息内容类型不能为空', trigger: 'change' }
        ],
        userType: [
          { required: true, message: '用户类型不能为空', trigger: 'change' }
        ]
      }
    }
  },
  filter: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onJsonChange(value) {
      // 实时保存
      this.onJsonSave(value)
    },
    onJsonSave(value) {
      this.jsonExt = value
      this.form.templateExt = JSON.stringify(value)
      this.hasJsonFlag = true
    },
    onError(value) {
      console.log('json错误了,value:', value)
      this.hasJsonFlag = false
    },
    // 检查json
    checkJson() {
      if (this.hasJsonFlag === false) {
        this.$message.error('请输入格式正确的JSON数据!')
        return false
      } else {
        return true
      }
    },
    // 定义表单字段规则函数，该函数要验证字段不能为空，该字段只能是英文字母、数字或下划线，并且必须以英文字母开头
    validateParamName (rule, value, callback) {
      if (!value) {
        callback(new Error('模板名称不能为空'))
      } else if (!/^[a-zA-Z][a-zA-Z0-9_]*$/.test(value)) {
        callback(new Error('模板名称只能是英文字母、数字或下划线，并且必须以英文字母开头'))
      } else {
        callback()
      }
    },
    handleDeleteParam (index) {
      this.form.paramsList.splice(index, 1)
    },
    handleAddParam () {
      this.form.paramsList.push({
        paramName: undefined,
        orderNum: undefined
      })
    },
    onChangePushRange (value) {
      if (value === 0 || value === 1) {
        this.msgUserTypeOptionsNew = this.msgUserTypeOptions
      } else {
        this.msgUserTypeOptionsNew = this.msgUserTypeOptions.filter(item => {
          return parseInt(item.dictValue) === 2 || parseInt(item.dictValue) === 3
        })
      }
    },
    onChangeUserType (value) {
      switch (value) {
        case 1:
          this.msgChannelTypeOptionsNew = this.msgChannelTypeOptions.filter(item => {
            return parseInt(item.dictValue) === 1 || parseInt(item.dictValue) === 2 || parseInt(item.dictValue) === 3 || parseInt(item.dictValue) === 4 || parseInt(item.dictValue) === 5 || parseInt(item.dictValue) === 6
          })
          break
        case 2:
          this.msgChannelTypeOptionsNew = this.msgChannelTypeOptions.filter(item => {
            return parseInt(item.dictValue) === 1 || parseInt(item.dictValue) === 2 || parseInt(item.dictValue) === 4 || parseInt(item.dictValue) === 5 || parseInt(item.dictValue) === 6
          })
          break
        case 3:
          this.msgChannelTypeOptionsNew = this.msgChannelTypeOptions.filter(item => {
            return parseInt(item.dictValue) === 3
          })
          break
        case 4:
          this.msgChannelTypeOptionsNew = this.msgChannelTypeOptions.filter(item => {
            return parseInt(item.dictValue) === 4 || parseInt(item.dictValue) === 5 || parseInt(item.dictValue) === 6
          })
          break
        default:
          this.msgChannelTypeOptionsNew = []
      }
    },
    onChangeChannelType (value) {
      queryMessageTypesByChannelType(value).then((response) => {
        this.messageTypeOptions = response.data
      })
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        templateId: null,
        templateName: undefined,
        templateContent: undefined,
        templateExt: null,
        pushRange: undefined,
        channelType: undefined,
        messageType: undefined,
        useCount: 0,
        templateStatus: 0,
        userType: undefined,
        paramsList: [],
        createUser: null,
        updateUser: null,
        createTime: null,
        updateTime: null,
        delFlag: null
      }
      this.jsonExt = {}
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加模板'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const templateId = row ? row.templateId : ids
      getTemplate(templateId).then(response => {
        this.form = response.data
        this.jsonExt = JSON.parse(this.form.templateExt)
        this.onChangePushRange(this.form.pushRange)
        this.onChangeUserType(this.form.userType)
        this.onChangeChannelType(this.form.channelType)
        this.open = true
        this.formTitle = '修改模板'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          // 获取this.form.templateContent内%s的数量，要考虑%s在this.form.templateContent最前或在this.form.templateContent最后的情况
          const paramsCount = this.form.templateContent.match(/%s/g)
          const paramsCountRequired = paramsCount ? paramsCount.length : 0
          if (paramsCountRequired !== this.form.paramsList.length) {
            this.$message.error(
              '模板内容参数数量与模板参数数量不一致',
              3
            )
            return
          }
          if (this.form.templateId !== undefined && this.form.templateId !== null) {
            updateTemplate(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          } else {
            addTemplate(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
/** 用::v-deep穿透样式修改所引用组件内的样式 */
::v-deep .jsoneditor-poweredBy {
  display: none !important;
}

::v-deep div.jsoneditor-menu {
  background-color: #023a08;
  border-bottom: 1px solid #013a07;
}
::v-deep div.jsoneditor {
  border: 1px solid #023a08;
  border-radius: 10px;
}

.template-form {
  .ant-form-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2px;
    .ant-form-item-label {
      flex: 0 0 80px;
    }
    ::v-deep .ant-form-item-control-wrapper {
      flex: 1;
      width: 90%;
    }
  }
  .input-remark {
    margin-left: 10px;
  }
  .bottom-control {
    border-top: 0;
    padding-top: 30px;
  }
}
.param-list {
  .title {
    font-size: 16px;
    font-weight: bold;
  }
  .row {
    display: flex;
    .item-delete {
      font-size: 20px;
    }
    .col {
      flex: 1;
      text-align: center;
      border-top: 1px solid #e8e8e8;
      border-left: 1px solid #e8e8e8;
    }
    .header {
      height: 30px;
      line-height: 30px;
      padding: 0 2px;
    }
    .col.header::before {
      display: inline-block;
      margin-right: 4px;
      color: #f5222d;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
    .col:first-child {
      flex: 1;
      min-width: 280px;
    }
    .delete-btn {
      flex: 0 0 30px;
      border: 0;
      border-left: 1px solid #e8e8e8;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .add {
      width: 100%;
    }
    .ant-input-number {
      border-radius: 10px;
      width: 100%;
      border: 1px solid #e8e8e8;
    }
    input {
      border-radius: 10px;
      text-align: center;
    }
    .ant-time-picker {
      border: 0;
    }
    .ant-time-picker-input {
      border: 0 !important;
    }
    .connect-flag {
      border: 0;
      width: 5px;
    }
  }
  .row:last-child {
    .col {
      border-bottom: 1px solid #e8e8e8;
    }
  }
  .child-row {
    width: 100%;
    display: flex;
    .col {
      flex: 1;
      text-align: center;
      vertical-align: middle;
      padding: 3px 4px;
      border-top: 1px solid #e8e8e8;
      border-left: 1px solid #e8e8e8;
      .ant-form-item {
        margin-bottom: 2px;
      }
    }
    .col:first-child {
      flex: 1;
      min-width: 280px;
    }
    .delete-btn {
      flex: 0 0 30px;
      border: 0;
      border-left: 1px solid #e8e8e8;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .child-row:last-child {
    .col {
      border-bottom: 1px solid #e8e8e8;
    }
  }
}
.add-btn {
  width: 100%;
  margin-top: 20px;
  margin-right: 30px;
  margin-bottom: 20px;
  button {
    width: 100%;
  }
}
</style>
