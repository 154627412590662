var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.open
    ? _c(
        "vxe-modal",
        {
          ref: "templateModelRef",
          attrs: {
            width: "1200px",
            height: "auto",
            title: _vm.formTitle,
            "show-zoom": "",
            resize: "",
            "lock-view": false,
            mask: true,
            "esc-closable": true,
            position: { top: "10%" },
            remember: "",
            transfer: "",
          },
          on: {
            close: function ($event) {
              _vm.open = false
            },
          },
          model: {
            value: _vm.open,
            callback: function ($$v) {
              _vm.open = $$v
            },
            expression: "open",
          },
        },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              staticClass: "template-form",
              attrs: { model: _vm.form, rules: _vm.rules },
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: 48 } },
                [
                  _c(
                    "a-col",
                    { attrs: { md: 12, sm: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "模板名称", prop: "templateName" } },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入模板名称" },
                            model: {
                              value: _vm.form.templateName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "templateName", $$v)
                              },
                              expression: "form.templateName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { md: 12, sm: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "推送范围", prop: "pushRange" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: { placeholder: "请选择" },
                              on: { change: _vm.onChangePushRange },
                              model: {
                                value: _vm.form.pushRange,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "pushRange", $$v)
                                },
                                expression: "form.pushRange",
                              },
                            },
                            _vm._l(
                              _vm.msgPushScopeOptions,
                              function (d, index) {
                                return _c(
                                  "a-select-option",
                                  {
                                    key: index,
                                    attrs: { value: parseInt(d.dictValue) },
                                  },
                                  [_vm._v(_vm._s(d.dictLabel))]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { md: 12, sm: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "用户类型", prop: "userType" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: { placeholder: "请选择" },
                              on: { change: _vm.onChangeUserType },
                              model: {
                                value: _vm.form.userType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "userType", $$v)
                                },
                                expression: "form.userType",
                              },
                            },
                            _vm._l(
                              _vm.msgUserTypeOptionsNew,
                              function (d, index) {
                                return _c(
                                  "a-select-option",
                                  {
                                    key: index,
                                    attrs: { value: parseInt(d.dictValue) },
                                  },
                                  [_vm._v(_vm._s(d.dictLabel))]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { md: 12, sm: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "渠道选择", prop: "channelType" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: { placeholder: "请选择" },
                              on: { change: _vm.onChangeChannelType },
                              model: {
                                value: _vm.form.channelType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "channelType", $$v)
                                },
                                expression: "form.channelType",
                              },
                            },
                            _vm._l(
                              _vm.msgChannelTypeOptionsNew,
                              function (d, index) {
                                return _c(
                                  "a-select-option",
                                  {
                                    key: index,
                                    attrs: { value: parseInt(d.dictValue) },
                                  },
                                  [_vm._v(_vm._s(d.dictLabel))]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { md: 12, sm: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "内容类型", prop: "messageType" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.form.messageType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "messageType", $$v)
                                },
                                expression: "form.messageType",
                              },
                            },
                            _vm._l(_vm.messageTypeOptions, function (d, index) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: d.code } },
                                [_vm._v(_vm._s(d.name))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { md: 12, sm: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: "模板状态", prop: "templateStatus" },
                        },
                        [
                          _c(
                            "a-radio-group",
                            {
                              attrs: { "button-style": "solid" },
                              model: {
                                value: _vm.form.templateStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "templateStatus", $$v)
                                },
                                expression: "form.templateStatus",
                              },
                            },
                            _vm._l(_vm.statusOptions, function (d, index) {
                              return _c(
                                "a-radio-button",
                                {
                                  key: index,
                                  attrs: { value: parseInt(d.dictValue) },
                                },
                                [_vm._v(_vm._s(d.dictLabel))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("a-divider"),
                  _c(
                    "a-col",
                    { attrs: { md: 12, sm: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: "模板内容", prop: "templateContent" },
                        },
                        [
                          _c("a-textarea", {
                            attrs: {
                              placeholder: "请输入模板内容",
                              autosize: { minRows: 4 },
                            },
                            model: {
                              value: _vm.form.templateContent,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "templateContent", $$v)
                              },
                              expression: "form.templateContent",
                            },
                          }),
                          _vm._v(" (模板内容内所有变量都必须以%s表示) "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { md: 12, sm: 24 } }, [
                    _c(
                      "div",
                      { staticClass: "param-list" },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("span", { staticClass: "col header" }, [
                            _vm._v("变量名"),
                          ]),
                          _c("span", { staticClass: "col header" }, [
                            _vm._v("变量顺序"),
                          ]),
                          _c("span", { staticClass: "delete-btn header" }),
                        ]),
                        _vm._l(_vm.form.paramsList, function (item, index) {
                          return _c("div", { key: index, staticClass: "row" }, [
                            _c("div", { staticClass: "child-row" }, [
                              _c(
                                "span",
                                { staticClass: "col" },
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        prop:
                                          "paramsList[" + index + "].paramName",
                                        rules: [
                                          {
                                            required: true,
                                            trigger: "change",
                                            validator: _vm.validateParamName,
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c("a-input", {
                                        attrs: { placeholder: "请输入变量名" },
                                        model: {
                                          value: item.paramName,
                                          callback: function ($$v) {
                                            _vm.$set(item, "paramName", $$v)
                                          },
                                          expression: "item.paramName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                { staticClass: "col" },
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        prop:
                                          "paramsList[" + index + "].orderNum",
                                        rules: [
                                          {
                                            required: true,
                                            trigger: "change",
                                            message: "请输入变量顺序",
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c("a-input-number", {
                                        attrs: {
                                          placeholder: "请输入变量顺序",
                                        },
                                        model: {
                                          value: item.orderNum,
                                          callback: function ($$v) {
                                            _vm.$set(item, "orderNum", $$v)
                                          },
                                          expression: "item.orderNum",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                { staticClass: "delete-btn" },
                                [
                                  _c("a-icon", {
                                    attrs: { type: "delete" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDeleteParam(index)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ])
                        }),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      { staticClass: "add-btn" },
                      [
                        _c(
                          "a-button",
                          {
                            staticClass: "add",
                            attrs: { disabled: _vm.readOnly, type: "primary" },
                            on: { click: _vm.handleAddParam },
                          },
                          [_c("a-icon", { attrs: { type: "plus" } })],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "a-col",
                    { attrs: { md: 12, sm: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: "模板扩展参数", prop: "templateExt" },
                        },
                        [
                          _c("vue-json-editor", {
                            attrs: {
                              showBtns: false,
                              mode: "code",
                              lang: "zh",
                              "expanded-on-start": true,
                            },
                            on: {
                              "json-change": _vm.onJsonChange,
                              "json-save": _vm.onJsonSave,
                              "has-error": _vm.onError,
                            },
                            model: {
                              value: _vm.jsonExt,
                              callback: function ($$v) {
                                _vm.jsonExt = $$v
                              },
                              expression: "jsonExt",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("a-divider"),
                  _c(
                    "div",
                    { staticClass: "bottom-control" },
                    [
                      _c(
                        "a-space",
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.submitForm },
                            },
                            [_vm._v(" 保存 ")]
                          ),
                          _c(
                            "a-button",
                            {
                              attrs: { type: "dashed" },
                              on: { click: _vm.cancel },
                            },
                            [_vm._v(" 取消 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }